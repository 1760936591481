import React, { useEffect } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';


const AddCoupon = () => {
	useEffect(() => {
		checkSession();
	}, []);
	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const couponCode = formData.get('couponCode');
		const offerPercentage = formData.get('offerPercentage');
		const validityTill = formData.get('validityTill');
		const token = localStorage.getItem('token');

		// Validate coupon code
		if (!couponCode) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Coupon code is required.'
			});
			return;
		}

		if (!offerPercentage || isNaN(offerPercentage) || offerPercentage <= 0 || offerPercentage > 100) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Offer percentage must be a valid number between 1 and 100.'
			});
			return;
		}

		if (!validityTill) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Validity date is required.'
			});
			return;
		}

		const currentDate = new Date();
		const selectedDate = new Date(validityTill);
		if (selectedDate <= currentDate) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Validity date must be a future date.'
			});
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/api/discounts/create`, formData, {
				couponCode: couponCode,
				offerPercentage: offerPercentage,
				validityTill: validityTill,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 201) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message,
				});
				console.log('Banner created successfully:', response.data);
				event.target.reset();
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/coupon">Coupon</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add Coupon</li>
									</ol>
								</nav>
							</div>
							<Link to="/coupon" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">New Coupon</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>

								<Form className="row g-3" onSubmit={handleSubmit}>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="couponCode" className="form-label font-weight-bold text-muted text-uppercase">Coupon Code</Form.Label>
										<Form.Control type="text" className="form-control text-uppercase" name="couponCode" id="CouponCode" placeholder="Coupon Code" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="offerPercentage" className="form-label font-weight-bold text-muted text-uppercase">Offer %</Form.Label>
										<Form.Control type="text" className="form-control" name="offerPercentage" id="OfferPercentage" placeholder="Offer %" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="validityTill" className="form-label font-weight-bold text-muted text-uppercase">Validity Till</Form.Label>
										<Form.Control type="date" name="validityTill" id="ValidityTill" />
									</div>
									<div className="col-12">
										<Button variant="primary" type="submit" style={{ marginTop: '18px' }}>Create Coupon</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Container>
		</>
	)
}
export default AddCoupon;