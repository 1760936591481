import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Form, Button, InputGroup, CloseButton } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams } from 'react-router-dom'
import TextArea from '../Forms/FormControls/TextArea'
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';



const EditCourse = () => {

	const { id } = useParams();
	const [curriculum, setCurriculum] = useState([
		{ title: '', contentVideoPairs: [{ content: '', videoUrl: '' }] }
	]);

	const [courseDetails, setCourseDetails] = useState({
		title: '',
		author: '',
		level: '',
		category: '',
		duration: '',
		subject: '',
		lectures: '',
		language: '',
		price: '',
		discountPrice: '',
		videoUrl: '',
		description: '',
		requirements: '',
		expiryPeriod: '',
		certificateIncluded: '',
		courseImage: '',
		courseMaterials: [],
		curriculum: []
	});

	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const fetchCategories = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/categories/list`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setCategories(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const fetchCourseDetails = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');

		try {
			const response = await axios.get(`${API_URL}/api/courses/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				const fetchedData = response.data.data;
				setCourseDetails(fetchedData);
				setCurriculum(fetchedData.curriculum); // Ensure the curriculum is updated based on the fetched data
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchCourseDetails();
	}, [id]);

	const handleAddCurriculum = () => {
		setCurriculum([...curriculum, { title: '', contentVideoPairs: [{ content: '', videoUrl: '' }] }]);
	};

	const handleAddContentVideoPair = (curriculumIndex) => {
		const newCurriculum = [...curriculum];
		if (curriculumIndex >= 0 && curriculumIndex < newCurriculum.length) {
			newCurriculum[curriculumIndex].contentVideoPairs.push({ content: '', videoUrl: '' });
			setCurriculum(newCurriculum);
		} else {
			console.error("Invalid curriculumIndex on add:", curriculumIndex);
		}
	};

	const handleDeleteContentVideoPair = (curriculumIndex, pairIndex) => {
		const newCurriculum = [...curriculum];
		newCurriculum[curriculumIndex].contentVideoPairs.splice(pairIndex, 1);
		setCurriculum(newCurriculum);
	};

	const handleCurriculumChange = (curriculumIndex, pairIndex, field, value) => {
		console.log("Curriculum Index:", curriculumIndex, "Pair Index:", pairIndex);
		const newCurriculum = [...curriculum];

		// Check if the curriculum index is valid
		if (curriculumIndex >= 0 && curriculumIndex < newCurriculum.length) {
			if (pairIndex === null) {
				// Directly update the field at the curriculum level
				newCurriculum[curriculumIndex][field] = value;
			} else {
				const pairs = newCurriculum[curriculumIndex].contentVideoPairs;

				// Check if the pair index is valid
				if (pairIndex >= 0 && pairIndex < pairs.length) {
					pairs[pairIndex][field] = value;
				} else {
					console.error("Invalid pairIndex:", pairIndex);
				}
			}
			setCurriculum(newCurriculum);
		} else {
			console.error("Invalid curriculumIndex:", curriculumIndex);
		}
	};

	const handleRemoveCurriculumItem = (index) => {
		const newCurriculum = [...curriculum];
		newCurriculum.splice(index, 1);
		setCurriculum(newCurriculum);
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked, files } = e.target;
		if (type === "checkbox") {
			setCourseDetails(prev => ({ ...prev, [name]: checked }));
		} else if (type === "file") {
			if (name === 'courseMaterials') {
				// Handle multiple files for course materials
				setCourseDetails(prev => ({ ...prev, [name]: Array.from(files) }));
			} else {
				// Handle single file for course image
				setCourseDetails(prev => ({ ...prev, [name]: files[0] }));
			}
		} else {
			setCourseDetails(prev => ({ ...prev, [name]: value }));
		}
	};

	const prepareCurriculumData = (curriculum) => {
		return curriculum.map(item => ({
			title: item.title,
			contentVideoPairs: item.contentVideoPairs.map(pair => ({
				content: pair.content,
				videoUrl: pair.videoUrl
			}))
		}));
	};
	const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		const token = localStorage.getItem('token');
		const formData = new FormData();

		// Prepare curriculum data by removing _id fields
		const preparedCurriculum = prepareCurriculumData(courseDetails.curriculum);

		// Append all text fields as normal
		Object.keys(courseDetails).forEach(key => {
			if (key === 'curriculum') {
				// Append the prepared curriculum data
				formData.append(key, JSON.stringify(preparedCurriculum));
			} else if (key === 'courseMaterials' && courseDetails[key]) {
				// Append each file in the courseMaterials array
				courseDetails[key].forEach(file => {
					formData.append('courseMaterials', file);
				});
			} else {
				formData.append(key, courseDetails[key]);
			}
		});

		// Make the API request to update the course
		try {
			const response = await axios.put(`${API_URL}/api/courses/update/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Course updated successfully!',
				}).then((result) => {
					if (result.isConfirmed) {
						//window.location.reload(); // Reload the page or redirect as needed
					}
				});
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};

	useEffect(() => {
		setCourseDetails(prevDetails => ({
			...prevDetails,
			curriculum: curriculum
		}));
	}, [curriculum]);
	useEffect(() => {
		fetchCategories();
	}, []);
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/manage-course">Course</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add new course</li>
									</ol>
								</nav>
							</div>
							<Link to="/manage-course" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center"> Add New Course</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Course Information</h5>
								<Form className="row g-3" id="course-form">
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Course Title</Form.Label>
										<Form.Control type="text" className="form-control" name='title' value={courseDetails.title} onChange={handleInputChange} id="Text1" placeholder="Enter Course Title" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Course Author</Form.Label>
										<Form.Control type="text" className="form-control" name='author' value={courseDetails.author} onChange={handleInputChange} id="Text1" placeholder="Enter Author" />
									</div>

									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Course Level</Form.Label>
										<select id="inputState" className="form-select form-control choicesjs" name='level' value={courseDetails.level} onChange={handleInputChange}>
											<option>Select</option>
											<option value="Beginer">Beginer</option>
											<option value="Intermediate">Intermediate</option>
											<option value="Advanced">Advanced</option>
											<option value="Expert">Expert</option>
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="inputCategory" className="form-label font-weight-bold text-muted text-uppercase">Course Category</Form.Label>
										<select id="inputCategory" className="form-select form-control choicesjs" name='category' value={courseDetails.category} onChange={handleInputChange}>
											<option>Select Category</option>
											{categories.map((category, index) => (
												<option key={index} value={category._id}>{category.title}</option>
											))}
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Duration</Form.Label>
										<Form.Control type="text" className="form-control" name='duration' value={courseDetails.duration} onChange={handleInputChange} id="Text1" placeholder="Enter Duration" />
									</div>

									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Subject</Form.Label>
										<Form.Control type="text" className="form-control" name='subject' value={courseDetails.subject} onChange={handleInputChange} id="Text1" placeholder="Subject" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Lectures</Form.Label>
										<select id="inputState" className="form-select form-control choicesjs" name='lectures' value={courseDetails.lectures} onChange={handleInputChange}>
											<option>Select</option>
											<option value="9 Lectures">9 Lectures</option>
											<option value="12 Lectures">12 Lectures</option>
											<option value="15 Lectures">15 Lectures</option>
											<option value="18 Lectures">18 Lectures</option>
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Language</Form.Label>
										<select id="inputState" className="form-select form-control choicesjs" name='language' value={courseDetails.language} onChange={handleInputChange}>
											<option>Select</option>
											<option value="English">English</option>
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Course Price</Form.Label>
										<Form.Control type="text" className="form-control" name='price' value={courseDetails.price} onChange={handleInputChange} id="Text1" placeholder="Enter Course Price" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Discount Price</Form.Label>
										<Form.Control type="text" className="form-control" name='discountPrice' value={courseDetails.discountPrice} onChange={handleInputChange} id="Text1" placeholder="Enter Discount Price" />
									</div >
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Post Introduction (video url) </Form.Label>
										<Form.Group controlId="exampleInputurl">
											<Form.Control type="url" name='videoUrl' value={courseDetails.videoUrl} onChange={handleInputChange} placeholder="Enter Url" />
										</Form.Group>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Description</Form.Label>
										<Form.Control as="textarea" name='description' value={courseDetails.description} onChange={handleInputChange} id="Text9" rows="2" placeholder="Enter Description" />
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">What Will You Learn?</Form.Label>
										<Form.Control as="textarea" name='requirements' value={courseDetails.requirements} onChange={handleInputChange} id="Text9" rows="2" placeholder="Enter What will you learn" />
									</div>

									<div className="col-md-6 mb-3">
										<div className="d-flex align-items-center">
											<Form.Label htmlFor="expiryPeriod" className="form-label font-weight-bold text-muted text-uppercase mr-3">Expiry period</Form.Label>
											<div className="custom-control custom-radio custom-radio-color-checked">
												<Form.Check.Input
													type="radio"
													id="customRadio-1"
													name="expiryPeriod"  // This should match the state property
													value="Lifetime"  // Value representing this choice
													checked={courseDetails.expiryPeriod === 'Lifetime'}  // Check if this should be checked
													onChange={handleInputChange}  // Attach handleInputChange here
													bsPrefix="custom-control-input bg-primary"
												/>
												<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio-1">Lifetime</Form.Check.Label>
											</div>
											<div className="custom-control custom-radio custom-radio-color-checked ml-4">
												<Form.Check.Input
													type="radio"
													id="customRadio-2"
													name="expiryPeriod"  // This should match the state property
													value="Limited Time"  // Value representing this choice
													checked={courseDetails.expiryPeriod === 'Limited Time'}  // Check if this should be checked
													onChange={handleInputChange}  // Attach handleInputChange here
													bsPrefix="custom-control-input bg-primary"
												/>
												<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio-2">Limited Time</Form.Check.Label>
											</div>
										</div>
									</div>
									<div className="col-md-6 mb-3">
										<div className="d-flex align-items-center">
											<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase mr-3">Certificate</Form.Label>
											<div className="custom-control custom-radio custom-radio-color-checked">
												<Form.Check.Input type="radio" id="customRadio-3" onchecked={courseDetails.certificateIncluded} onChange={handleInputChange} name="customRadio-10" bsPrefix="custom-control-input bg-primary" />
												<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio-3">Included</Form.Check.Label>
											</div>
											<div className="custom-control custom-radio custom-radio-color-checked ml-4">
												<Form.Check.Input type="radio" id="customRadio-4" onchecked={courseDetails.certificateIncluded} onChange={handleInputChange} name="customRadio-10" bsPrefix="custom-control-input bg-primary" />
												<Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio-4">Not Included</Form.Check.Label>
											</div>
										</div>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="courseFiles" className="form-label font-weight-bold text-muted text-uppercase">Course Materials</Form.Label>
										<InputGroup className="mb-4">
											<div className="custom-file">
												<input type="file" className="custom-file-input" id="courseMaterials" name='courseMaterials' multiple onChange={handleInputChange} />
												<label className="custom-file-label" htmlFor="courseMaterials" required>Choose files</label>
											</div>
										</InputGroup>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Course Img</Form.Label>
										<InputGroup className="mb-4">
											<div className="custom-file">
												<input type="file" className="custom-file-input" id="courseImage" name='courseImage' onChange={handleInputChange} />
												<label className="custom-file-label" htmlFor="courseImage" required>Choose file</label>
											</div>
										</InputGroup>
									</div>

									{curriculum.map((item, curriculumIndex) => (
										<div key={curriculumIndex} className="col-md-12 mb-3" style={{ marginBottom: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
											<h5 className="font-weight-bold mb-3">Course Curriculum {curriculumIndex + 1}</h5>
											<div className="col-md-12 mb-3">

												<Form.Label className="form-label font-weight-bold text-muted text-uppercase">Title</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter title"
													value={item.title}
													onChange={(e) => handleCurriculumChange(curriculumIndex, null, 'title', e.target.value)}
												/>

											</div>

											{item.contentVideoPairs.map((pair, pairIndex) => (
												<div key={pairIndex}>
													<div className="col-md-12 mb-4">
														<Form.Label className="form-label font-weight-bold text-muted text-uppercase">Content</Form.Label>
														<Form.Control
															type="text"
															placeholder="Enter content"
															value={pair.content}
															onChange={(e) => handleCurriculumChange(curriculumIndex, pairIndex, 'content', e.target.value)}
														/>
													</div>
													<div className="col-md-12 mb-3">
														<Form.Label className="form-label font-weight-bold text-muted text-uppercase">Video URL</Form.Label>
														<Form.Control
															type="url"
															placeholder="Enter video URL"
															value={pair.videoUrl}
															onChange={(e) => handleCurriculumChange(curriculumIndex, pairIndex, 'videoUrl', e.target.value)}
														/>
													</div>
													<Button variant="danger" style={{ marginBottom: '10px', marginRight: '10px' }} onClick={() => handleDeleteContentVideoPair(curriculumIndex, pairIndex)}>Delete</Button>
												</div>
											))}
											<Button variant="primary" style={{ marginRight: '10px' }} onClick={() => handleAddContentVideoPair(curriculumIndex)}>Add Content</Button>
											<Button variant="danger" onClick={() => handleRemoveCurriculumItem(curriculumIndex)}>Remove Curriculum</Button>
										</div>
									))}
									<div className="col-md-12 mb-3">
										<Button variant="primary" onClick={handleAddCurriculum}>Add Curriculum Item</Button>
									</div>
									<div className="d-flex justify-content-start mt-1">
										<Button variant="btn btn-primary" onClick={handleSubmit}>
											Update Course
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>



					</Col>
				</Row>
			</Container>
		</>
	)
}

export default EditCourse;