import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';
import { ThreeDots } from 'react-loader-spinner';

const BannerList = () => {
	useEffect(() => {
		checkSession();
	}, []);

	const [banners, setBanners] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [error, setError] = useState(null);
	const rowsPerPage = 10;
	const totalRows = banners.length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);


	const fetchBanners = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/banners/list`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setBanners(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		} finally {
			setLoading(false);
		}
	};


	const handleDelete = async (categoryId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				const token = localStorage.getItem('token');
				try {
					const response = await axios.delete(`${API_URL}/api/banners/delete/${categoryId}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});

					if (response.status === 200) {
						Swal.fire(
							'Deleted!',
							response.data.message,
							'success'
						);
						fetchBanners();
					} else {
						handleNon200Response(response);
					}
				} catch (error) {
					if (error.response) {
						handleNon200Response(error.response);
					} else {
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Unexpected Error! Please check your network connection.'
						});
					}
				}
			}
		}).catch((error) => {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Failed to delete the category. Please try again.'
			});
		});
	};


	useEffect(() => {
		fetchBanners();
	}, []);
	function convertToCSV(arr) {
		const array = [Object.keys(arr[0])].concat(arr);
		return array.map(it => {
			return Object.values(it).toString();
		}).join('\n');
	}

	const handleExport = () => {
		const csvData = convertToCSV(banners);
		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', 'banners.csv');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>

			<Container fluid>
				<Row>

					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Course Category</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<input type="text" className="form-control" id="exampleInputText" placeholder="Search Order" />
												<Link className="search-link" to="#">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</Form.Group>
										</Form>
										<Link to="/category" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
											<svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
											Add Category
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Category List</h5>
											<Button variant="btn btn-secondary btn-sm" onClick={handleExport}>
												<svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col">
															<label className="text-muted m-0" >ID</label>
															{/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="ID"/>  */}
														</th>
														<th scope="col" className="dates">
															<label className="text-muted mb-0" >Banner</label>
															{/* <input type="text" className="form-control" id="usr1" name="event_date" placeholder="Enter Date" autocomplete="off" aria-describedby="basic-addon2"/> */}
														</th>

														<th scope="col" className="text-right">
															<span className="text-muted" >Action</span>
															{/* <p className="text-muted"></p>  */}
														</th>
													</tr>
												</thead>
												<tbody>
													{loading ? (
														<tr>
															<td colSpan="6" style={{ textAlign: "center" }}>
																<ThreeDots color="#00BFFF" height={80} width={80} />
															</td>
														</tr>
													) : (
														banners.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((item, index) => (
															<tr key={item._id} className="white-space-no-wrap">
																<td>
																	{index + 1}
																</td>
																<td>
																	<img src={`${API_URL}/${item.file}`} alt="Category" width="50" height="50" />
																</td>
																<td>
																	<div className="d-flex justify-content-end align-items-center">
																		<OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
																			<Link to={`/edit-category/${item._id}`}>
																				<svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
																				</svg>
																			</Link>
																		</OverlayTrigger>
																		<OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
																			<Button variant="link" onClick={() => handleDelete(item._id)}>
																				<svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="red">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
																				</svg>
																			</Button>
																		</OverlayTrigger>
																	</div>
																</td>
															</tr>
														))
													)}
												</tbody>
											</table>
											<div className="d-flex justify-content-center">
												<Pagination className="mb-0">
													<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
													{[...Array(totalPages).keys()].map(pageNumber => (
														<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
															{pageNumber + 1}
														</Pagination.Item>
													))}
													<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
												</Pagination>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default BannerList;
