import React from "react";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";

//img
import logo from "../../../assets/images/logo.png";
import darklogo from "../../../assets/images/logo-dark.png";

function mapStateToProps(state) {
	return {
		darkMode: getDarkMode(state),
	};
}

const SignIn = (props) => {
	const handleLogin = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const email = formData.get('email');
		const password = formData.get('password');

		try {
			const response = await axios.post(`${API_URL}/api/admin/signin`, {
				email,
				password
			});

			if (response.status !== 200) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Invalid username or password!',
				});
				throw new Error('Failed to login');
			}

			const data = response.data;
			console.log('Login successful:', data);
			localStorage.setItem('token', data.token);
			const userRole = JSON.parse(atob(data.token.split('.')[1])).role;
			window.location.href = userRole === 'quiz' ? '/manage-quiz' : '/dashboard';
		} catch (error) {
			console.error('Login error:', error);
			Swal.fire({
				icon: 'error',
				title: 'Login Failed',
				text: error.message,
			});
		}
	};
	return (
		<>
			<section className="login-content">
				<Container className="h-100">
					<Row className="align-items-center justify-content-center h-100">
						<Col md="5">
							<Card className="p-3">
								<Card.Body>
									<div className="auth-logo">
										<img
											src={logo}
											className={`img-fluid  rounded-normal  ${!props.darkMode ? "d-none" : ""
												}`}
											alt="logo"
										/>
										<img
											src={darklogo}
											className={`img-fluid  rounded-normal  ${props.darkMode ? "d-none" : ""
												}`}
											alt="logo"
										/>
									</div>
									<h3 className="mb-3 font-weight-bold text-center">Sign In</h3>
									<p className="text-center text-secondary mb-4">
										Log in to your account to continue
									</p>
									<Form onSubmit={handleLogin}>
										<Row>
											<Col lg="12">
												<Form.Group controlId="formBasicEmail">
													<Form.Label className="text-secondary">
														Email
													</Form.Label>
													<Form.Control
														type="email"
														placeholder="Enter Email"
														name="email"
														required
													/>
												</Form.Group>
											</Col>
											<Col lg="12" className="mt-2">
												<Form.Group controlId="formBasicPassword">
													<Form.Label className="text-secondary">
														Password
													</Form.Label>
													<Form.Control
														type="password"
														placeholder="Enter Password"
														name="password"
														required
													/>
												</Form.Group>
											</Col>
										</Row>
										<Button type="submit" className="btn btn-primary btn-block mt-2">
											Log In
										</Button>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default connect(mapStateToProps)(SignIn);
