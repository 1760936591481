import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Form, Button, InputGroup, CloseButton } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams } from 'react-router-dom'
import TextArea from '../Forms/FormControls/TextArea'
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';



const EditEvent = () => {

	const { id } = useParams();

	const [eventDetails, setEventDetails] = useState({
		title: '',
		zoomClassDate: '',
		zoomClassTime: '',
		duration: '',
		subject: '',
		language: '',
		price: '',
		zoomLink: '',
		description: '',
		courseMaterials: [],
		courseImage: ''
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchEventDetails = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`${API_URL}/api/events/admin/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});
			if (response.status === 200) {
				setEventDetails(response.data.data);
				const expiryDate = response.data.data.zoomClassDate;
				const formattedDate = new Date(expiryDate).toISOString().split('T')[0];
				setEventDetails(prevDetails => ({
					...prevDetails,
					zoomClassDate: formattedDate,
					zoomClassTime: new Date(expiryDate).toISOString().split('T')[1].slice(0, 5)
				}));
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			console.error('Failed to fetch event details:', error);
			Swal.fire({
				icon: 'error',
				title: 'Failed to Fetch Event',
				text: 'There was a problem fetching the event details. Please try again later.'
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			fetchEventDetails();
		}
	}, []);

	const handleInputChange = (event) => {
		const { name, type, files, value } = event.target;
		console.log("event.target", files);
		if (type === "file") {
			if (files && files.length > 0) {  // Check if files are selected
				setEventDetails({ ...eventDetails, [name]: files[0] });
				console.log("files[0]", files[0]);
			} else if (name === 'courseImage') {
				// Keep the old file if new file is not selected and it's mandatory
				setEventDetails(prev => ({ ...prev, [name]: prev[name] }));
				//console.log("prev[name]", prev[name]);
			}
		} else {
			setEventDetails(prev => ({ ...prev, [name]: value }));
			console.log("value", value);
		}
	}

	const validateForm = () => {
		const errors = {};
		const { title, zoomClassDate, zoomClassTime, duration, subject, language, price, zoomLink, description } = eventDetails;

		if (!title.trim()) errors.title = "Title is required";
		if (!zoomClassDate.trim()) errors.zoomClassDate = "Class date is required";
		if (!zoomClassTime.trim()) errors.zoomClassTime = "Class time is required";
		if (!duration.trim()) errors.duration = "Duration is required";
		if (!subject.trim()) errors.subject = "Subject is required";
		if (!language.trim()) errors.language = "Language is required";
		if (!price.toString().trim()) errors.price = "Price is required";
		if (!zoomLink.trim()) errors.zoomLink = "Zoom link is required";
		if (!description.trim()) errors.description = "Description is required";
		if (!eventDetails.courseImage) errors.courseImage = "Course image is required";

		setError(errors);
		return Object.keys(errors).length === 0;
	}

	const handleSubmit = async (event) => {
		if (!validateForm()) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				html: Object.entries(error).map(([key, value]) => `<div>${value}</div>`).join(''),
			});
			return;
		}
		event.preventDefault(); // Prevent default form submission behavior
		const token = localStorage.getItem('token');
		const formData = new FormData();

		// Append each detail to formData
		Object.keys(eventDetails).forEach(key => {
			if (key === 'courseMaterials' || key === 'courseImage') {
				// Handle file inputs; check if it's multiple files or a single file
				const files = eventDetails[key];
				if (files.length) {
					Array.from(files).forEach(file => {
						formData.append(key, file);
					});
				} else {
					formData.append(key, files[0]); // Single file
				}
			} else {
				formData.append(key, eventDetails[key]);
			}
		});
		console.log("formData", formData);
		try {
			const response = await axios.put(`${API_URL}/api/events/update/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message,
				}).then((result) => {
					if (result.isConfirmed) {
						//window.location.reload();
					}
				});
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/manage-course">Zoom Classes</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add new Zoom Class</li>
									</ol>
								</nav>
							</div>
							<Link to="/manage-course" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center"> Update Zoom Class</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Zoom Class Information</h5>
								<Form className="row g-3" id="course-form">
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Event Title</Form.Label>
										<Form.Control type="text" className="form-control" name='title' value={eventDetails.title} onChange={handleInputChange} id="Text1" placeholder="Enter Event Title" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="zoomClassDate" className="form-label font-weight-bold text-muted text-uppercase">Zoom Class Date</Form.Label>
										<Form.Control type="date" className="form-control" name='zoomClassDate' value={eventDetails.zoomClassDate} onChange={handleInputChange} id="zoomClassDate" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="zoomClassTime" className="form-label font-weight-bold text-muted text-uppercase">Zoom Class Time</Form.Label>
										<Form.Control type="time" className="form-control" name='zoomClassTime' value={eventDetails.zoomClassTime} onChange={handleInputChange} id="zoomClassTime" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Duration</Form.Label>
										<Form.Control type="text" className="form-control" name='duration' value={eventDetails.duration} onChange={handleInputChange} id="Text1" placeholder="Enter Duration" />
									</div>

									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Subject</Form.Label>
										<Form.Control type="text" className="form-control" name='subject' value={eventDetails.subject} onChange={handleInputChange} id="Text1" placeholder="Subject" />
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Language</Form.Label>
										<select id="inputState" className="form-select form-control choicesjs" name='language' value={eventDetails.language} onChange={handleInputChange}>
											<option>Select</option>
											<option value="English">English</option>
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Zoom Class Price</Form.Label>
										<Form.Control type="text" className="form-control" name='price' value={eventDetails.price} onChange={handleInputChange} id="Text1" placeholder="Enter Class Price" />
									</div>

									<div className="col-md-6 mb-3">
										<Form.Label htmlFor="zoomLink" className="form-label font-weight-bold text-muted text-uppercase">Zoom Link</Form.Label>
										<Form.Control type="text" className="form-control" name='zoomLink' value={eventDetails.zoomLink} onChange={handleInputChange} id="zoomLink" placeholder="Enter Zoom Link" />
									</div>

									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Description</Form.Label>
										<Form.Control as="textarea" name='description' value={eventDetails.description} onChange={handleInputChange} id="Text9" rows="2" placeholder="Enter Description" />
									</div>

									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="courseFiles" className="form-label font-weight-bold text-muted text-uppercase">Zoom Class Materials</Form.Label>
										<InputGroup className="mb-4">
											<div className="custom-file">
												<input type="file" className="custom-file-input" id="courseMaterials" name='courseMaterials' multiple onChange={handleInputChange} />
												<label className="custom-file-label" htmlFor="courseMaterials" required>Choose files</label>
											</div>
										</InputGroup>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Zoom Class Thumbnail</Form.Label>
										<InputGroup className="mb-4">
											<div className="custom-file">
												<input type="file" className="custom-file-input" id="courseImage" name='courseImage' onChange={handleInputChange} />
												<label className="custom-file-label" htmlFor="courseImage" required>Choose file</label>
											</div>
										</InputGroup>
									</div>
									<div className="col-md-12 mb-3">
									</div>
									<div className="d-flex justify-content-start mt-1">
										<Button variant="btn btn-primary" onClick={handleSubmit}>
											Update Zoom Class
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>



					</Col>
				</Row>
			</Container>
		</>
	)
}

export default EditEvent;