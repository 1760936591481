import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';
import { ThreeDots } from 'react-loader-spinner';
// img

const Subscription = () => {
	useEffect(() => {
		checkSession();
	}, []);
	const [subscriptions, setSubscriptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [error, setError] = useState(null);
	const rowsPerPage = 10;
	const totalRows = subscriptions ? subscriptions.length : 0;
	const totalPages = Math.ceil(totalRows / rowsPerPage);

	const fetchSubscriptions = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/subscribe/list`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setSubscriptions(response.data.subscriptions);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchSubscriptions();
	}, []);

	function convertToCSV(arr) {
		const array = [Object.keys(arr[0])].concat(arr);
		return array.map(it => {
			return Object.values(it).toString();
		}).join('\n');
	}

	const handleExport = () => {
		const csvData = convertToCSV(subscriptions);
		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', 'subscriptions.csv');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Subscription</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Subscription" />
												<Link to="#" className="search-link">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</Form.Group>
										</Form>

									</div>
								</div>
							</div>
						</div>
						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Subscription List</h5>
											<Button variant="btn btn-secondary btn-sm" onClick={handleExport}>
												<svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="">
														<th scope="col">
															SL No.
														</th>
														<th scope="col">
															Name
														</th>
														<th scope="col">
															Email
														</th>
														<th scope="col">
															Subscription ID
														</th>
														<th scope="col">
															Paypal Payment ID
														</th>
														<th scope="col">
															Payment Status
														</th>
														<th scope="col">
															Is Active
														</th>
														<th scope="col">
															Start Date
														</th>
														<th scope="col">
															End Date
														</th>
													</tr>
												</thead>
												<tbody>
													{
														loading ? (
															<tr>
																<td colSpan="6" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
																	<ThreeDots color="#00BFFF" height={80} width={80} />
																</td>
															</tr>
														) : subscriptions && subscriptions.length > 0 ? (
															subscriptions.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((item, index) => (
																<tr key={item._id} className="white-space-no-wrap">
																	<td>{index + 1}</td>
																	<td className="">
																		<div className="active-project-1 d-flex align-items-center mt-0 ">
																			<div className="data-content">
																				<div>
																					<span className="font-weight-bold">{item.fullname}</span>
																				</div>
																			</div>
																		</div>
																	</td>
																	<td>{item.email}</td>
																	<td>
																		{item.subscriptionId}
																	</td>
																	<td>
																		{item.paypalPaymentId}
																	</td>
																	<td>
																		{item.paymentStatus}
																	</td>
																	<td>
																		<span className={`badge ${item.isActive ? 'badge-success' : 'badge-danger'}`}>
																			{item.isActive ? 'Active' : 'Inactive'}
																		</span>
																	</td>
																	<td>
																		{new Date(item.startDate).toLocaleDateString()}
																	</td>
																	<td>
																		{new Date(item.endDate).toLocaleDateString()}
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan="6" className="text-center">No subscription available</td>
															</tr>
														)
													}
												</tbody>
											</table>
											<div className="d-flex justify-content-center">
												<Pagination className="mb-0">
													<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
													{[...Array(totalPages).keys()].map(pageNumber => (
														<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
															{pageNumber + 1}
														</Pagination.Item>
													))}
													<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
												</Pagination>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

		</>

	)
}
export default Subscription;

