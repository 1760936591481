import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, CloseButton } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';

const Quiz = () => {
	useEffect(() => {
		checkSession();
	}, []);
	const [questions, setQuestions] = useState([]);
	const [quizTitle, setQuizTitle] = useState('');
	const [quizBanner, setQuizBanner] = useState(null);
	const [quizDescription, setQuizDescription] = useState('');
	const [quizTime, setQuizTime] = useState('');

	const handleAddQuestion = () => {
		const newQuestion = {
			questionText: "",
			options: [{ text: "", image: null }, { text: "", image: null }, { text: "", image: null }, { text: "", image: null }],
			answer: ""
		};
		setQuestions(questions => [...questions, newQuestion]);
	};

	const handleChangeQuestionText = (index, text) => {
		const newQuestions = [...questions];
		newQuestions[index].questionText = text;
		setQuestions(newQuestions);
	};

	const handleChangeOptionText = (questionIndex, optionIndex, text) => {
		const newQuestions = [...questions];
		newQuestions[questionIndex].options[optionIndex].text = text;
		setQuestions(newQuestions);
	};

	const handleChangeOptionImage = (questionIndex, optionIndex, file) => {
		const newQuestions = [...questions];
		newQuestions[questionIndex].options[optionIndex].image = file;
		setQuestions(newQuestions);
	};

	const handleSetAnswer = (questionIndex, answer) => {
		const newQuestions = [...questions];
		newQuestions[questionIndex].answer = answer;
		setQuestions(newQuestions);
	};

	const handleDeleteQuestion = (index) => {
		const updatedQuestions = questions.filter((_, i) => i !== index);
		setQuestions(updatedQuestions);
	};

	const validateQuestions = () => {
		if (questions.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Please add at least one question.'
			});
			return false;
		}
		if (!quizTitle.trim()) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Please enter a title for the quiz.'
			});
			return false;
		}

		for (let i = 0; i < questions.length; i++) {
			const question = questions[i];
			if (!question.questionText.trim() && !question.document) {
				Swal.fire({
					icon: 'error',
					title: 'Validation Error',
					text: `Question ${i + 1} must have either a question text or a document attached.`
				});
				return false;
			}

			if (question.options.length !== 4) {
				Swal.fire({
					icon: 'error',
					title: 'Validation Error',
					text: `Question ${i + 1} must have exactly four options.`
				});
				return false;
			}

			for (let j = 0; j < question.options.length; j++) {
				const option = question.options[j];
				if (!(option.text.trim() && !option.image) && !(option.image && !option.text.trim())) {
					Swal.fire({
						icon: 'error',
						title: 'Validation Error',
						text: `Option ${String.fromCharCode(65 + j)} in Question ${i + 1} must contain either text or an image, but not both.`
					});
					return false;
				}
			}

			if (!question.answer.trim()) {
				Swal.fire({
					icon: 'error',
					title: 'Validation Error',
					text: `Please specify the correct answer for Question ${i + 1}.`
				});
				return false;
			}
		}

		return true;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateQuestions()) {
			return;
		}

		const formData = new FormData();
		const token = localStorage.getItem('token');

		// Append quiz title and banner
		formData.append('title', quizTitle);
		formData.append('description', quizDescription);
		formData.append('time', quizTime);
		if (quizBanner instanceof File) {
			formData.append('banner', quizBanner, quizBanner.name);
		}

		// Append questions as a JSON string excluding file references
		formData.append('questions', JSON.stringify(questions.map(question => ({
			...question,
			document: undefined, // Exclude the document file from JSON, it will be appended separately
			options: question.options.map(option => ({
				text: option.text,
				image: undefined // Exclude the image file from JSON, it will be appended separately
			}))
		}))));

		// Append question documents and option images separately
		questions.forEach((question, index) => {
			if (question.document instanceof File) {
				formData.append(`questions[${index}][document]`, question.document, question.document.name);
			}
			question.options.forEach((option, optionIndex) => {
				if (option.image instanceof File) {
					formData.append(`questions[${index}][options][${optionIndex}][image]`, option.image, option.image.name);
				}
			});
		});

		try {
			const response = await axios.post(`${API_URL}/api/quizzes/create`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 201) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message,
				});
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/manage-course">Quiz</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add New Quiz</li>
									</ol>
								</nav>
							</div>

						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">Quiz</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Quiz Curriculum</h5>
								<Form.Control
									type="text"
									placeholder="Enter Quiz Title"
									value={quizTitle}
									onChange={(e) => setQuizTitle(e.target.value)}
									className="mb-4"
								/>
								<div className="custom-file align-items-center d-flex">
									<input type="file" className="custom-file-input" id="customFile-banner" onChange={(e) => {
										setQuizBanner(e.target.files[0]);
										const label = document.querySelector('label[for="customFile-banner"]');
										label.textContent = e.target.files[0] ? e.target.files[0].name : 'Upload Quiz Banner';
									}} />
									<label className="custom-file-label flex-grow-1" htmlFor="customFile-banner" style={{ color: 'gray', overflow: 'hidden', flex: '1', marginRight: '60px' }}>Upload Quiz Banner</label>
									<button type="button" className="btn btn-secondary btn-sm" onClick={() => {

										const fileInput = document.getElementById('customFile-banner');
										const label = document.querySelector('label[for="customFile-banner"]');
										fileInput.value = ''; // Clear the file input
										label.textContent = 'Upload Quiz Banner';
									}}>Clear</button>
								</div>
								<div style={{ height: '20px' }}></div>
								<Form.Group controlId="quizTime">
									<Form.Label className="font-weight-bold">Quiz Time (in minutes)</Form.Label>
									<Form.Control
										type="number"
										placeholder="Enter duration in minutes"
										value={quizTime}
										onChange={(e) => setQuizTime(e.target.value)}
										min="1"
									/>
								</Form.Group>
								<Form.Group controlId="quizDescription">
									<Form.Label className="form-labe font-weight-bold " style={{ marginTop: '16px' }}>Quiz Description</Form.Label>
									<Form.Control
										as="textarea"
										rows={3}
										placeholder="Enter a detailed description of the quiz"
										value={quizDescription}
										onChange={(e) => setQuizDescription(e.target.value)}
									/>
								</Form.Group>
								<Form className="row g-3" onSubmit={handleSubmit}>
									<div className="col-md-12 mb-3">
										{questions.map((question, questionIndex) => (
											<div key={questionIndex} style={{ backgroundColor: questionIndex % 2 === 0 ? '#f8f9fa' : '#e9ecef' }}>
												<h5 className="font-weight-bold mb-3">Question {questionIndex + 1}</h5>
												<div className="col-md-6 mb-3">
													<Form.Label className="form-label font-weight-bold text-muted text-uppercase">Question</Form.Label>
													<Form.Control type="text" placeholder="Enter Question" onChange={(e) => handleChangeQuestionText(questionIndex, e.target.value)} />
												</div>
												<div className="col-md-6 mb-3">
													<Form.Label className="form-label font-weight-bold text-muted text-uppercase">Question Document</Form.Label>
													<div className="custom-file align-items-center d-flex">
														<input type="file" className="custom-file-input" id={`customFile-doc-${questionIndex}`} onChange={(e) => {
															const newQuestions = [...questions];
															newQuestions[questionIndex].document = e.target.files[0];
															setQuestions(newQuestions);
															const label = document.querySelector(`label[for="customFile-doc-${questionIndex}"]`);
															label.textContent = e.target.files[0] ? e.target.files[0].name : 'Choose Document';
														}} />
														<label className="custom-file-label flex-grow-1" htmlFor={`customFile-doc-${questionIndex}`} style={{ color: 'gray', overflow: 'hidden', flex: '1', marginRight: '60px' }}>Choose Document</label>
														<button type="button" className="btn btn-secondary btn-sm" onClick={() => {

															const fileInput = document.getElementById(`customFile-doc-${questionIndex}`);
															const label = document.querySelector(`label[for="customFile-doc-${questionIndex}"]`);
															fileInput.value = ''; // Clear the file input
															label.textContent = 'Choose Document';
														}}>Clear</button>
													</div>
												</div>
												{question.options.map((option, optionIndex) => (
													<div className="col-md-6 mb-3" key={`${questionIndex}-${optionIndex}`} style={{ backgroundColor: optionIndex % 2 === 0 ? '#6437cc' : '#36d92e' }}>
														<div style={{ height: '20px' }}></div>
														<Form.Label className="form-label font-weight-bold text-uppercase" style={{ color: 'black' }}>Option {String.fromCharCode(65 + optionIndex)}</Form.Label>
														<Form.Control type="text" placeholder={`Option ${String.fromCharCode(65 + optionIndex)}: Enter Option`} onChange={(e) => handleChangeOptionText(questionIndex, optionIndex, e.target.value)} />
														<div style={{ marginBottom: '10px', textAlign: 'center' }}>OR</div>
														<div className="custom-file align-items-center d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
															<input type="file" className="custom-file-input" id={`customFile-${questionIndex}-${optionIndex}`} onChange={(e) => {
																handleChangeOptionImage(questionIndex, optionIndex, e.target.files[0]);
																const label = document.querySelector(`label[for="customFile-${questionIndex}-${optionIndex}"]`);
																label.textContent = e.target.files[0] ? e.target.files[0].name : 'Choose Image';
															}} />
															<label className="custom-file-label" htmlFor={`customFile-${questionIndex}-${optionIndex}`} style={{ color: 'gray', overflow: 'hidden', flex: '1', marginRight: '60px' }}>Choose Image</label>
															<button type="button" className="btn btn-secondary btn-sm" onClick={() => {
																const fileInput = document.getElementById(`customFile-${questionIndex}-${optionIndex}`);
																const label = document.querySelector(`label[for="customFile-${questionIndex}-${optionIndex}"]`);
																fileInput.value = ''; // Clear the file input
																label.textContent = 'Choose Image';
															}}>Clear</button>
														</div>
														<div style={{ height: '20px' }}></div>
													</div>
												))}
												<div className="col-md-6 mb-3">
													<Form.Label htmlFor={`inputState-${questionIndex}`} className="form-label font-weight-bold text-muted text-uppercase">Select Answer</Form.Label>
													<select id={`inputState-${questionIndex}`} className="form-select form-control choicesjs" onChange={(e) => handleSetAnswer(questionIndex, e.target.value)}>
														<option>Select</option>
														<option value="A">A</option>
														<option value="B">B</option>
														<option value="C">C</option>
														<option value="D">D</option>
													</select>
												</div>
												<CloseButton onClick={() => handleDeleteQuestion(questionIndex)} style={{ marginRight: '-39px', position: 'relative', bottom: '817px', color: 'red' }} />
											</div>
										))}
										<div className="col-md-12 mb-3">
											<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase" style={{ marginTop: '16px' }}> Quiz Curriculum (Question & Answer) </Form.Label>
											<Button variant="primary mr-2" onClick={handleAddQuestion}>
												<FontAwesomeIcon icon={faPlus} style={{ marginLeft: '-6px' }} />
											</Button>
										</div>
										<Button variant="btn btn-primary" type="submit">
											Submit
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};
export default Quiz;