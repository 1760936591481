import React, { useEffect } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';





const AddBanner = () => {
	useEffect(() => {
		checkSession();
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const token = localStorage.getItem('token');

		const fileInput = formData.get('filename');
		console.log(fileInput);
		if (!fileInput || fileInput.size === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Please select a file.'
			});
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/api/banners/create`, formData, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 201) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message,
				});
				console.log('Banner created successfully:', response.data);
				event.target.reset();
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};
	return (
		<>

			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/banner">Banner</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add Banner</li>
									</ol>
								</nav>
							</div>
							<Link to="/banner" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">New Banner</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Upload Banner Image (50x50)</h5>
								<Form onSubmit={handleSubmit}>
									<Form.File custom className="mb-3">
										<Form.File.Input id="customFile" name="filename" />
										<Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
									</Form.File>
									<div className="mt-3">
										<Button type="submit">Submit</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Container>

		</>
	)
}
export default AddBanner;
