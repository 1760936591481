import React, { useEffect } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';



const Category = () => {
	useEffect(() => {
		checkSession();
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const categoryTitle = formData.get('category_title');
		const categoryUrl = formData.get('category_url');
		const token = localStorage.getItem('token');

		if (!categoryTitle || !categoryUrl) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Both title and URL are required fields.'
			});
			return;
		}

		if (!categoryUrl.match(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/)) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Please enter a valid URL.'
			});
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/api/categories/create`, {
				title: categoryTitle,
				url: categoryUrl
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 201) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message || 'Category created successfully!',
				});
				console.log('Category created successfully:', response.data);
				event.target.reset();
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/category">Category</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Add Category</li>
									</ol>
								</nav>
							</div>
							<Link to="/course-category" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">New Category</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Form className="row g-3" onSubmit={handleSubmit}>
									<div className="col-md-12 mb-3" >
										<Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Category Title</Form.Label>
										<Form.Control type="text" name="category_title" className="form-control" id="Text1" placeholder="Category Name" style={{ width: '97rem' }} />
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label htmlFor="categoryUrl" className="form-label font-weight-bold text-muted text-uppercase">Category URL</Form.Label>
										<Form.Control type="url" name="category_url" className="form-control" id="categoryUrl" placeholder="Enter URL" />
									</div>

									<Button variant='btn btn-primary' type="submit">
										Create Category
									</Button>
								</Form>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Container>
		</>
	)
}
export default Category;