import Layout1 from "../layouts/backend/Layout1";
import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
// //main
import Dashbord from "../views/backend/Main/Dashbord";
// Calendar
import Calendar from "../views/backend/Main/Calendar";

// Customer
import Student from "../views/backend/Main/Student";
import Studentadd from "../views/backend/Main/Studentadd";
import Studentview from "../views/backend/Main/Studentview";
import Studentedit from "../views/backend/Main/Studentedit";

// Product



// order
import Order from "../views/backend/Main/Order";
import Ordernew from "../views/backend/Main/Ordernew";
import Orderdetails from "../views/backend/Main/Orderdetails";

//App
import UserProfile from "../views/backend/App/UserManagement/UserProfile";
import UserAdd from "../views/backend/App/UserManagement/UserAdd";
import UserList from "../views/backend/App/UserManagement/UserList";
import UserPrivacySetting from "../views/backend/App/UserManagement/UserPrivacySetting";
import UserAccountSettingList from "../views/backend/App/UserManagement/UserAccountSetting";
import UserProfileEdit from "../views/backend/App/UserManagement/UserProfileEdit";
import Chat from "../views/backend/App/Chat";
import Todo from "../views/backend/App/Todo";

//Chart
import ApexChart from "../views/backend/Chart/ApexChart";

//From
import Checkbox from "../views/backend/Forms/FormControls/Checkbox";
import Elements from "../views/backend/Forms/FormControls/Elements";
import Inputs from "../views/backend/Forms/FormControls/Inputs";
import Radio from "../views/backend/Forms/FormControls/Radio";
import FromSwitch from "../views/backend/Forms/FormControls/Switch";
import TextArea from "../views/backend/Forms/FormControls/TextArea";
import Validations from "../views/backend/Forms/FormControls/Validations";
import Datepicker from "../views/backend/Forms/Formwidget/Datepicker";
import Fileupload from "../views/backend/Forms/Formwidget/Fileupload";
import FormQuill from "../views/backend/Forms/Formwidget/FormQuill";
import Select from "../views/backend/Forms/Formwidget/Select";
import Invoiceview from "../views/backend/pages/Invoiceview";

//Extrapages
import Timeline1 from "../views/backend/pages/Timeline/Timeline1";
import Pricing1 from "../views/backend/pages/Pricing/Pricing1";
import Invoice from "../views/backend/pages/Invoice";
import FAQ from "../views/backend/pages/FAQ";
import BlankPage from "../views/backend/pages/BlankPage";
import TermsOfUse from "../views/backend/pages/Extrapages/TermsOfUse";
import PrivacyPolicy from "../views/backend/pages/Extrapages/PrivacyPolicy";

//Table
import BasicTable from "../views/backend/Table/BasicTable";
import DataTable from "../views/backend/Table/DataTable";
import EditTable from "../views/backend/Table/EditTable";

//ui
import UiAlerts from "../views/backend/ui/UiAlerts";
import UiAvatars from "../views/backend/ui/UiAvatars";
import UiBadges from "../views/backend/ui/UiBadges";
import UiBoxShadows from "../views/backend/ui/UiBoxShadows";
import UiBreadcrumbs from "../views/backend/ui/UiBreadcrumbs";
import UiButtonGroups from "../views/backend/ui/UiButtonGroups";
import UiButtons from "../views/backend/ui/UiButtons";
import UiCards from "../views/backend/ui/UiCards";
import UiCarousels from "../views/backend/ui/UiCarousels";
import UiColors from "../views/backend/ui/UiColors";
import UiEmbed from "../views/backend/ui/UiEmbed";
import UiGrids from "../views/backend/ui/UiGrids";
import UiImages from "../views/backend/ui/UiImages";
import UiListGroups from "../views/backend/ui/UiListGroups";
import UiMediaObjects from "../views/backend/ui/UiMediaObjects";
import UiModals from "../views/backend/ui/UiModals";
import UiNotifications from "../views/backend/ui/UiNotifications";
import UiPaginations from "../views/backend/ui/UiPaginations";
import UiPopOvers from "../views/backend/ui/UiPopOvers";
import UiProgressBars from "../views/backend/ui/UiProgressBars";
import UiTabs from "../views/backend/ui/UiTabs";
import UiTooltips from "../views/backend/ui/UiTooltips";
import UiTypography from "../views/backend/ui/UiTypography";
import Category from "../views/backend/Main/Category";
import CourseCategory from "../views/backend/Main/CourseCategory";

// icon-heroicon
import Heroicons from "../views/backend/Icons/Heroicons ";
import Coupon from "../views/backend/Main/Coupon";
import AddCoupon from "../views/backend/Main/AddCoupon";
import AddBundle from "../views/backend/Main/AddBundle";
import CourseList from "../views/backend/Main/CourseList";
import CourseAdd from "../views/backend/Main/CourseAdd";
import BundleList from "../views/backend/Main/BundleList";
import TicketList from "../views/backend/Main/TicketList";
import Instructor from "../views/backend/Main/Instructor";
import Instructoradd from "../views/backend/Main/Instructoradd";
import Instructoredit from "../views/backend/Main/Instructoredit";
import Instructorview from "../views/backend/Main/Instructorview";
import AddBanner from "../views/backend/Main/AddBanners";
import SignIn from "../views/backend/Auth/SignIn";
import BlankLayout from "../layouts/BlankLayout";

import CategoryEdit from "../views/backend/Main/CategoryEdit";
import CouponEdit from "../views/backend/Main/CouponEdit";
import BannerList from "../views/backend/Main/BannerList";

import Quiz from "../views/backend/Main/quiz";
import ManageQuiz from "../views/backend/Main/ManageQuiz";
import QuizSubmissions from "../views/backend/Main/QuizSubmissions";
import QuizAttempt from "../views/backend/Main/QuizAttempt";

import QuizEdit from "../views/backend/Main/QuizEdit";
import Subscription from "../views/backend/Main/Subscription";

import { Children } from "react";
import ManageEvents from "../views/backend/Main/ManageEvents";
import ManageEventAdd from "../views/backend/Main/ManageEventAdd";
import EventRegistration from "../views/backend/Main/EventRegistration";
import EventPayment from "../views/backend/Main/EventPayment";
import ViewRegistrations from "../views/backend/Main/ViewRegistrations";
import EditCourse from "../views/backend/Main/EditCourse";
import EditEvent from "../views/backend/Main/EditEvent";

export const Layout1Route = [
	{
		path: '/',
		element: <BlankLayout />,
		children: [
			{
				path: '/',
				element: <SignIn />,
			}
		]
	},
	{
		path: '/',
		element: <Layout1 />,
		children: [
			{
				path: "/dashboard",
				element: <Dashbord />
			},
			{
				path: "/user-profile",
				element: <UserProfile />
			},
			{
				path: "/user-add",
				element: <UserAdd />,
			},
			{
				path: "/user-list",
				element: <UserList />,
			},
			{
				path: "/user-privacy-setting",
				element: <UserPrivacySetting />,
			},
			{
				path: "/user-account-setting",
				element: <UserAccountSettingList />,
			},
			{
				path: "/user-profile-edit",
				element: <UserProfileEdit />,
			},
			{
				path: "/chat",
				element: <Chat />,
			},
			{
				path: "/todo",
				element: <Todo />,
			},
			{
				path: "/chart-apex",
				element: <ApexChart />,
			},
			{
				path: "/form-chechbox",
				element: <Checkbox />,
			},
			{
				path: "/form-layout",
				element: <Elements />,
			},
			{
				path: "/form-input-group",
				element: <Inputs />,
			},
			{
				path: "/form-radio",
				element: <Radio />,
			},
			{
				path: "/form-switch",
				element: <FromSwitch />,
			},
			{
				path: "/form-textarea",
				element: <TextArea />,
			},
			{
				path: "/form-validation",
				element: <Validations />,
			},
			{
				path: "/form-datepicker",
				element: <Datepicker />,
			},
			{
				path: "/form-file-uploader",
				element: <Fileupload />,
			},
			{
				path: "/form-quill",
				element: <FormQuill />,
			},
			{
				path: "/form-select",
				element: <Select />,
			},
			{
				path: "/icon-heroicon",
				element: <Heroicons />,
			},
			{
				path: "/timeline-1",
				element: <Timeline1 />,
			},
			{
				path: "/pricing-1",
				element: <Pricing1 />,
			},
			{
				path: "/pages-invoice",
				element: <Invoice />,
			},
			{
				path: "/pages-faq",
				element: <FAQ />,
			},
			{
				path: "/pages-blank-page",
				element: <BlankPage />,
			},
			{
				path: "/terms-of-service",
				element: <TermsOfUse />,
			},
			{
				path: "/privacy-policy",
				element: <PrivacyPolicy />,
			},
			{
				path: "/invoice-view",
				element: <Invoiceview />,
			},
			{
				path: "/tables-basic",
				element: <BasicTable />,
			},
			{
				path: "/tables-data",
				element: <DataTable />,
			},
			{
				path: "/tables-editable",
				element: <EditTable />,
			},
			{
				path: "/ui-alerts",
				element: <UiAlerts />,
			},
			{
				path: "/ui-avatars",
				element: <UiAvatars />,
			},
			{
				path: "/ui-badges",
				element: <UiBadges />,
			},
			{
				path: "/ui-boxshadow",
				element: <UiBoxShadows />,
			},
			{
				path: "/ui-breadcrumb",
				element: <UiBreadcrumbs />,
			},
			{
				path: "/ui-buttons-group",
				element: <UiButtonGroups />,
			},
			{
				path: "/ui-buttons",
				element: <UiButtons />,
			},
			{
				path: "/ui-cards",
				element: <UiCards />,
			},
			{
				path: "/ui-carousel",
				element: <UiCarousels />,
			},
			{
				path: "/ui-colors",
				element: <UiColors />,
			},
			{
				path: "/ui-embed-video",
				element: <UiEmbed />,
			},
			{
				path: "/ui-grid",
				element: <UiGrids />,
			},
			{
				path: "/ui-images",
				element: <UiImages />,
			},
			{
				path: "/ui-list-group",
				element: <UiListGroups />,
			},
			{
				path: "/ui-media-object",
				element: <UiMediaObjects />,
			},
			{
				path: "/ui-modal",
				element: <UiModals />,
			},
			{
				path: "/ui-notifications",
				element: <UiNotifications />,
			},
			{
				path: "/ui-pagination",
				element: <UiPaginations />,
			},
			{
				path: "/ui-popovers",
				element: <UiPopOvers />,
			},
			{
				path: "/ui-progressbars",
				element: <UiProgressBars />,
			},
			{
				path: "/ui-tabs",
				element: <UiTabs />,
			},
			{
				path: "/ui-tooltips",
				element: <UiTooltips />,
			},
			{
				path: "/ui-typography",
				element: <UiTypography />,
			},
			{
				path: "/student",
				element: <Student />,
			},
			{
				path: "/student-add",
				element: <Studentadd />,
			},
			{
				path: "/student-view/:id",
				element: <Studentview />,
			},
			{
				path: "/student-edit/:id",
				element: <Studentedit />,
			},
			{
				path: "/manage-course",
				element: <CourseList />,
			},
			{
				path: "/course-add",
				element: <CourseAdd />,
			},
			{
				path: "/order",
				element: <Order />,
			},
			{
				path: "/order-new",
				element: <Ordernew />,
			},
			{
				path: "/order-details/:id",
				element: <Orderdetails />,
			},
			{
				path: "/calendar",
				element: <Calendar />,
			},
			{
				path: '/category',
				element: <Category />
			},
			{
				path: '/edit-category/:id',
				element: <CategoryEdit />
			},
			{
				path: '/course-category',
				element: <CourseCategory />
			},
			{
				path: '/coupon',
				element: <Coupon />
			},
			{
				path: '/coupon-add',
				element: <AddCoupon />
			},
			{
				path: '/bundle-add',
				element: <AddBundle />
			},
			{
				path: '/manage-bundle',
				element: <BundleList />
			},
			{
				path: '/tickets-list',
				element: <TicketList />
			},
			{
				path: '/Instructor',
				element: <Instructor />
			},
			{
				path: '/instructor-add',
				element: <Instructoradd />
			},
			{
				path: '/instructor-edit',
				element: <Instructoredit />
			},
			{
				path: '/instructor-view',
				element: <Instructorview />
			},
			{
				path: '/banner-add',
				element: <AddBanner />
			},
			{
				path: '/edit-coupon/:id',
				element: <CouponEdit />
			},
			{
				path: '/banner-list',
				element: <BannerList />
			},
			{
				path: '/quiz',
				element: <Quiz />
			},
			{
				path: '/manage-quiz',
				element: <ManageQuiz />
			},
			{
				path: '/quiz-submissions/:id',
				element: <QuizSubmissions />
			},
			{
				path: '/quiz-attempt/:quizId/:userId',
				element: <QuizAttempt />
			},
			{
				path: '/quiz-edit/:id',
				element: <QuizEdit />
			},
			{
				path: '/subscription',
				element: <Subscription />
			},
			{
				path: '/manage-event',
				element: <ManageEvents />
			},
			{
				path: '/add-event',
				element: <ManageEventAdd />
			},
			{
				path: '/event-registrations',
				element: <EventRegistration />
			},
			{
				path: '/event-payments',
				element: <EventPayment />
			},
			{
				path: '/view-registrations/:id',
				element: <ViewRegistrations />
			},
			{
				path: '/edit-course/:id',
				element: <EditCourse />
			},
			{
				path: '/edit-event/:id',
				element: <EditEvent />
			}
		]
	}

];
