import React, { useState, useEffect } from 'react'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';
import { ThreeDots } from 'react-loader-spinner';

// img
import product1 from '../../../assets/images/products/consult.jpg'
import product6 from '../../../assets/images/products/consult.jpg'
import product8 from '../../../assets/images/products/consult.jpg'


const Orderdetails = () => {
	const { id } = useParams();
	const [checkout, setCheckout] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const fetchCheckout = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/checkout/admin/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				setCheckout(response.data.data);
				console.log(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (err) {
			if (err.response) {
				handleNon200Response(err.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		}
	};
	useEffect(() => {
		fetchCheckout();
	}, []);

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/order">Orders</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Order Details</li>
									</ol>
								</nav>
							</div>
						</div>
					</Col>
					<Col lg="12" className="mb-3">
						<div className="d-flex justify-content-between align-items-center">
							<h4 className="font-weight-bold">Order Details</h4>
							{checkout && checkout.invoiceFile && (
								<a className="btn btn-primary btn-sm" href={`${API_URL}${checkout.invoiceFile}`} target="_blank" rel="noopener noreferrer">
									<svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
									</svg>
									View Invoice
								</a>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="4">
						<Card>
							<ListGroup as="ul" className="list-group-flush">
								<ListGroup.Item as="li" className="p-3">
									<h5 className="font-weight-bold pb-2">Order Info</h5>
									<div className="table-responsive">
										<table className="table table-borderless mb-0">
											<tbody>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Order ID
													</td>
													<td>
														{checkout ? checkout.orderId : 'N/A'}
													</td>
												</tr>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Paypal ID
													</td>
													<td>
														{checkout ? checkout.paypalOrderId : 'N/A'}
													</td>
												</tr>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Date &#38; Time
													</td>
													<td>
														{checkout ? new Date(checkout.createdAt).toLocaleString() : 'N/A'}
													</td>
												</tr>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Payment Method
													</td>
													<td>
														PayPal
													</td>
												</tr>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Invoice
													</td>
													<td className="text-primary">
														{checkout ? (
															<a href={`${API_URL}${checkout.invoiceFile}`} target="_blank" rel="noopener noreferrer">
																View
															</a>
														) : (
															<span>N/A</span>
														)}
													</td>
												</tr>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Status
													</td>
													<td>
														<p className="mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center">
															<small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
																<circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
															</small>{checkout ? checkout.paymentStatus : 'N/A'}
														</p>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="p-3">
									<h5 className="font-weight-bold pb-2">Customer Details</h5>
									<div className="table-responsive">
										<table className="table table-borderless mb-0">
											<tbody>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Name
													</td>
													<td>
														{checkout ? checkout.userId.fullname : 'N/A'}
													</td>
												</tr>
												<tr className="white-space-no-wrap">
													<td className="text-muted pl-0">
														Email
													</td>
													<td>
														{checkout ? checkout.userId.email : 'N/A'}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card>
					</Col>
					<Col lg="8">
						<Card>
							<ListGroup as="ul" className="list-group-flush">
								<ListGroup.Item as="li" className="p-3">
									<h5 className="font-weight-bold">Order Items</h5>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="p-0">
									<div className="table-responsive">
										<table className="table mb-0">
											<thead>
												<tr className="text-muted">
													<th scope="col">Course Name</th>
													<th scope="col" className="text-right">Quantity</th>
													<th scope="col" className="text-right">Price</th>
												</tr>
											</thead>
											<tbody>
												{checkout ? checkout.courseIds.map((course) => (
													<tr key={course._id}>
														<td>
															<div className="active-project-1 d-flex align-items-center mt-0">
																<div className="h-avatar is-medium">
																	<img className="avatar rounded" alt="course-icon" src={`${API_URL}/${course.courseImage}`} />
																</div>
																<div className="data-content">
																	<div>
																		<span className="font-weight-bold">{course.title}</span>
																	</div>
																</div>
															</div>
														</td>
														<td className="text-right">
															1
														</td>
														<td className="text-right">
															${course.discountPrice}
														</td>
													</tr>
												)) : (
													<tr>
														<td colSpan="3" className="text-center">
															No courses available.
														</td>
													</tr>
												)}

											</tbody>
										</table>
									</div>
								</ListGroup.Item>
								<ListGroup.Item as="li" className="p-3">
									<div className="d-flex justify-content-end">
										{checkout ? (
											<>
												Total: <p className="ml-2 mb-0 font-weight-bold">{checkout.totalAmount}</p>
											</>
										) : (
											<p className="ml-2 mb-0 font-weight-bold">Total: Not Available</p>
										)}
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Orderdetails;