import  React, {useState} from 'react'
import {Container,Row,Col,Form,Button,OverlayTrigger,Tooltip, Pagination, Dropdown, ButtonGroup} from 'react-bootstrap'
import  Card from '../../../components/Card'
import {Link} from 'react-router-dom'

// img
import product1 from '../../../assets/images/products/consult.jpg'
import product15 from '../../../assets/images/products/consult.jpg'
import product2 from '../../../assets/images/products/consult.jpg'
import product4 from '../../../assets/images/products/consult.jpg'

import product8 from '../../../assets/images/products/consult.jpg'

import product5 from '../../../assets/images/products/consult.jpg'

import product7 from '../../../assets/images/products/consult.jpg'
import product16 from '../../../assets/images/products/consult.jpg'
import product6 from '../../../assets/images/products/consult.jpg'
import product12 from '../../../assets/images/products/consult.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const Products=[
    {
        Pname:'Beginner To Expert',
        Cor:'Adobe Illustrator CC - Essentials Training Course',
        cor2: 'Complete Guitar Lesson System',
        cor3: 'The Complete Python Bootcamp From Zero to Hero',
        SL:'365 Days',
        Price:'$10',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'Complete Solution',
        Cor:'Adobe Illustrator CC - Essentials Training Course',
        cor2: 'After Effect CC 2024: Complete Course',
        cor3: 'Worpress Theme',
        SL:'365 Days',
        Price:'$70',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'Complete Study for Vue js',
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'700 Days',
        Price:'$320',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'Complete Wordpress Theme Development',
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'365 Days',
        Price:'$25',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'Growing Your Creativity',
        
        
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'600 Days',
        Price:'$75',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'Web Design and web Development',
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'300 Days',
        Price:'$55',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'Build Websites From Scratch With HTML & CSS',
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'365 Days',
        Price:'$70',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'After Effects CC 2024: Complete Course',
        
        
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'365 Days',
        Price:'$10',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'How To Use Lightning Design to Transform Your Home',
        
        
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'600 Days',
        Price:'$100',
        color:'text-success',
        status: 'Active'
    },
    {
        Pname:'The Complete Graphic Design Theory',
        
       
        Cor:'Wordpress Theme',
        cor2: 'Worpress Theme',
        cor3: 'Worpress Theme',
        SL:'365 Days',
        Price:'$90',
        color:'text-success',
        status: 'Active'
    },
]

const options = [
    'View'
  ];

const ITEM_HEIGHT = 48;

const BundleList =() => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return(
        <>
        <Container fluid>
        <Row>
            
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                   <div className="d-flex align-items-center justify-content-between">
                        <h4 className="font-weight-bold">Bundle</h4>
                    </div>  
                    <div className="create-workform">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="modal-product-search d-flex">
                                <Form className="mr-3 position-relative">
                                    <div className="form-group mb-0">
                                    <Form.Control type="text" className="form-control" id="exampleInputText"  placeholder="Search Product"/>
                                    <Link className="search-link" to="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </Link>
                                    </div>
                                </Form>
                                
                               
                            </div>    
                                                    
                                    
                        </div>
                        
                    </div>  
                </div>
                
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Bundle List</h5>
                                    <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-light">
                                                <th scope="col">
                                                    <label className="text-muted m-0" >Bundle</label>
                                                    {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Search Product">  */}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >Courses</label>
                                                    {/* <select className="custom-select custom-select-sm mt-2 choicesjs" id="validationServer01">
                                                        <option selected>Select Category</option>
                                                        <option value="1">Tablet</option>
                                                        <option value="2">Speaker</option>
                                                        <option value="3">Pendrive</option>
                                                        <option value="4">Mobile</option>
                                                        <option value="5">Laptop</option>
                                                        <option value="6">Headset</option>
                                                    </select>  */}
                                                </th>
                                                <th scope="col" className="text-right" >
                                                    <label className="text-muted mb-0" >Subscription Limit</label>
                                                     {/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="Price" />  */}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >Price</label>
                                                     {/* <input type="text" className="form-control mt-2" id="text3" aria-describedby="textHelp" placeholder="Quantity"> */}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >Status</label>
                                                     {/* <input type="text" className="form-control mt-2" id="text3" aria-describedby="textHelp" placeholder="Quantity"> */}
                                                </th>
                                                
                                                <th scope="col" className="text-right">
                                                    <span className="text-muted" >Action</span>
                                                    {/* <p className="text-muted"></p>  */}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                             {
                                                    Products.map( (item) => (
                                            <tr key={item.Lesson} className="white-space-no-wrap" >
                                                <td className=""  style={{position: 'relative', right: '10px'}}>
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold" style={{color: '#007dc6'}}>{item.Pname}</span>                           
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{position: 'relative', right:"27px"}}>
                                                <ul>
                                                <li style={{color: 'gray'}}>{item.Cor}</li>
                                                <li style={{color: 'gray'}}>{item.cor2}</li>
                                                <li style={{color: 'gray'}}>{item.cor3}</li>
                                                </ul>
                                                </td>
                                                <td className="text-right" style={{position: 'relative', left: '-7px',color: 'gray' }}>
                                                    {item.SL}
                                                </td>
                                                <td style={{position: 'relative', left: '4px', color: 'gray'}}>
                                                  {item.Price}
                                                </td>
                                                <td>
                                                    <p className={`mb-0 ${item.color} font-weight-bold d-flex justify-content-start align-items-center`}> 
                                                                                                      
                                                        {item.status}
                                                    </p>
                                                </td>
                                                <td style={{padding: '16px 31px'}}>
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                            <Link className="" to="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                </svg>
                                                            </Link>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                            <Link className="badge bg-danger" style={{marginLeft:'-14px'}} to="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </Link>
                                                        </OverlayTrigger>
                                                        
                                                           <IconButton
                                                              aria-label='more'
                                                              id='long-button'
                                                              aria-controls={open ? 'long-menu' : undefined}
                                                              aria-expanded={open ? 'true' : undefined}
                                                              aria-haspopup='true'
                                                              onClick={handleClick}
                                                           >
                                                            <FontAwesomeIcon icon={faEllipsisVertical} style={{marginLeft: '10px'}}/>
                                                           </IconButton>

                                                           <Menu
                                                           id='long-menu'
                                                           MenuListProps={{
                                                            "aria-labelledby" : 'long-button'
                                                           }}
                                                           anchorEl={anchorEl}
                                                           open={open}
                                                           onClose={handleClose}
                                                           PaperProps={{
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: '20ch',
                                                                boxShadow: "0px 0px 5px -3px rgba(0,0,0,-3.8), 0px 8px 10px 1px rgba(0,0,0,-0.86), 0px 3px 14px 2px rgba(0,0,0,0.12)"
                                                            }
                                                           }}
                                                           >
                                                            {options.map((options) => (
                                                                <MenuItem key={options} selected={options === 'pysix'} onClick={handleClose}>
                                                                    {options}
                                                                </MenuItem>
                                                            ))}
                                                           </Menu>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                              ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                                    <nav aria-label="Page navigation example">
   <Pagination>
      <Pagination.Item disabled>Previous</Pagination.Item>
      <Pagination.Item>1</Pagination.Item>
      <Pagination.Item>2</Pagination.Item>
      <Pagination.Item>3</Pagination.Item>
      <Pagination.Item>Next</Pagination.Item>
   </Pagination>
</nav>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
        </>
    )
}

export default BundleList;
