import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';
import { ThreeDots } from 'react-loader-spinner';

const Orders = [
	{
		id: 'OR-325548',
		date: '01 Jan 2021',
		cust: 'Jack Ryan',
		total: '$104.98',
		status: 'Completed',
		color: 'text-success'
	},


]


const Order = () => {

	useEffect(() => {
		checkSession();
	}, []);

	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [error, setError] = useState(null);
	const rowsPerPage = 10;
	const totalRows = orders.length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);


	const fetchCheckouts = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/checkout/all`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setOrders(response.data.data);
				console.log(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCheckouts();
	}, [currentPage]);

	function convertToCSV(arr) {
		const array = [Object.keys(arr[0])].concat(arr);
		return array.map(it => {
			return Object.values(it).toString();
		}).join('\n');
	}

	const handleExport = () => {
		const csvData = convertToCSV(orders);
		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', 'orders.csv');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	return (
		<>

			<Container fluid>
				<Row>

					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">All Orders</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<input type="text" className="form-control" id="exampleInputText" placeholder="Search Order" />
												<Link className="search-link" to="#">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</Form.Group>
										</Form>
										<Link to="/order-new" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
											<svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
											Add Order
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Orders List</h5>
											<Button variant="btn btn-secondary btn-sm" onClick={handleExport}>
												<svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col">
															<label className="text-muted m-0" >SL No.</label>
															{/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="ID"/>  */}
														</th>
														<th scope="col">
															<label className="text-muted m-0" >Order ID</label>
															{/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="ID"/>  */}
														</th>
														<th scope="col" className="dates">
															<label className="text-muted mb-0" >Date</label>
															{/* <input type="text" className="form-control" id="usr1" name="event_date" placeholder="Enter Date" autocomplete="off" aria-describedby="basic-addon2"/> */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0">Customer</label>
															{/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="Customer"/> */}
														</th>
														<th scope="col" className="text-right">
															<label className="text-muted mb-0">Total</label>
															{/* <input type="text" className="form-control mt-2" id="text3" aria-describedby="textHelp" placeholder="Total"/>  */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Invoice</label>
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Status</label>
														</th>
														<th scope="col" className="text-right">
															<span className="text-muted" >Action</span>
															{/* <p className="text-muted"></p>  */}
														</th>
													</tr>
												</thead>
												<tbody>
													{
														orders.map((item, index) => (
															<tr key={item._id} className="white-space-no-wrap">
																<td>
																	{index + 1}
																</td>
																<td>
																	{item.orderId}
																</td>
																<td>{new Date(item.orderDate).toLocaleDateString()}</td>
																<td>
																	{item.userId ? item.userId.fullname : "No user"}
																</td>
																<td className="text-right">
																	{item.totalAmount}
																</td>
																<td>
																	{item.invoiceFile ? <a href={API_URL + item.invoiceFile} target="_blank" rel="noopener noreferrer">Download</a> : "No invoice"}
																</td>
																<td>
																	<p className={`mb-0 ${item.paymentStatus === 'Successful' ? 'text-success' : 'text-danger'} font-weight-bold d-flex justify-content-start align-items-center`}>
																		<small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
																			<circle cx="12" cy="12" r="8" fill={item.paymentStatus === 'Successful' ? "#3cb72c" : "#ff0000"}></circle></svg>
																		</small>{item.paymentStatus}
																	</p>
																</td>
																<td>
																	<div className="d-flex justify-content-end align-items-center">
																		<OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
																			<Link className="" to={`/order-details/${item._id}`}>
																				<svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
																				</svg>
																			</Link>
																		</OverlayTrigger>
																	</div>
																</td>
															</tr>
														))

													}
												</tbody>
											</table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default Order;
