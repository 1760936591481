import  React from 'react'
import {Container,Row,Col,Form,Button,OverlayTrigger,Tooltip, InputGroup} from 'react-bootstrap'
import  Card from '../../../components/Card'
import {Link} from 'react-router-dom'
// import { formatIsoTimeString } from '@fullcalendar/react'




const AddBundle = ()=>{
    return(
        <>
          
          <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/coupon">Bundle</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Bundle</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/coupon" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">New Bundle</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                
                                <Form className="row g-3">
                                    <div className="col-md-6 mb-3" >
                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Bundle Title</Form.Label>
                                        <Form.Control type="text" className="form-control" id="Text1" placeholder="Title" style={{width: '97rem'}}/>
                                    </div> 
                                </Form>
                               <Form className='row g-3'>
                               <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Select Course</Form.Label>
                                        <Form.Control as="textarea" id="Text9" rows="2" placeholder="Select Course" />
                                        <span style={{color: 'gray'}}>Current price of the course is 24$</span>
                                    </div>
                               </Form>

                               <Form className='row g-3'>
                               <div className="col-md-6 mb-3" >
                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Bundle Price</Form.Label>
                                        <Form.Control type="text" className="form-control" id="Text1" placeholder="10" style={{width: '97rem'}}/>
                                </div>
                               </Form>

                               <Form className='row g-3'>
                               <div className="col-md-6 mb-3" >
                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Subscription Renew Day</Form.Label>
                                        <Form.Control type="text" className="form-control" id="Text1" placeholder="20" style={{width: '97rem'}}/>
                                </div>
                               </Form>

                               
                               <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Thumbnail</Form.Label>
                                        <InputGroup className="mb-4">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile02" />
                                                <label className="custom-file-label" htmlFor="inputGroupFile02">Choose file</label>
                                            </div>
                                            <InputGroup.Append>
                                                <InputGroup.Text id="upload">Upload</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                               
                               <Form className='row g-3'>
                               <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Bundle Detail</Form.Label>
                                        <Form.Control as="textarea" id="Text9" rows="2" placeholder="Select Course" />
                                        
                                    </div>
                               </Form>
                        <Button variant='btn btn-primary' style={{marginTop: '18px'}}>Create Coupon</Button>
                            </Card.Body>
                        </Card>
                       
                    </Col>
                </Row>
            </Container>
        
        </>
    )
}
export default AddBundle;
