import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';

const Studentedit = () => {
	const { id } = useParams();
	useEffect(() => {
		checkSession();
	}, []);

	const [loading, setLoading] = useState(false);
	const [studentDetails, setStudentDetails] = useState({});
	const fetchStudentDetails = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`${API_URL}/api/update/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});
			if (response.status === 200) {
				setStudentDetails(response.data.data);

			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			console.error('Failed to fetch event details:', error);
			Swal.fire({
				icon: 'error',
				title: 'Failed to Fetch Event',
				text: 'There was a problem fetching the event details. Please try again later.'
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchStudentDetails();
	}, [id]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const fullname = formData.get('fullname');
		const gender = formData.get('gender');
		const email = formData.get('email');
		console.log(email);
		const password = formData.get('password');
		const confirmPassword = formData.get('confirmPassword');
		const fileUpload = formData.get('fileUpload');
		const token = localStorage.getItem('token');

		const missingFields = [];
		if (!fullname) missingFields.push('Full Name');
		if (!gender) missingFields.push('Gender');
		if (!email) missingFields.push('Email');
		if (!password) missingFields.push('Password');
		if (!confirmPassword) missingFields.push('Confirm Password');

		if (missingFields.length > 0) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: `The following fields are required: ${missingFields.join(', ')}.`
			});
			return;
		}

		if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Please enter a valid email.'
			});
			return;
		}
		if (password !== confirmPassword) {
			Swal.fire({
				icon: 'error',
				title: 'Validation Error',
				text: 'Passwords do not match.'
			});
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/auth/signup`, {
				fullname: fullname,
				gender: gender,
				email: email,
				password: password,
				confirmPassword: confirmPassword,
				fileUpload: fileUpload
			}, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 201) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: response.data.message || 'Student created successfully!',
				});
				console.log('Student created successfully:', response.data);
				event.target.reset();
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error! Please check your network connection.'
				});
			}
		}
	};
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item"><Link to="/student">Student</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Update Student</li>
									</ol>
								</nav>
							</div>
							<Link to="/student" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold0 d-flex align-items-center">Update Student</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>

									<Col md="12">
										<Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
											<div className="col-md-6 mb-3">
												<Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Full Name</Form.Label>
												<Form.Control type="text" name="fullname" id="Text1" placeholder="Enter Full Name" />
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label className="font-weight-bold text-muted text-uppercase">Gender</Form.Label><br />
												<div className="form-check form-check-inline">
													<div className="custom-control custom-radio custom-control-inline">
														<Form.Control type="radio" id="inlineRadio1" name="gender" value="Male" className="custom-control-input" />
														<Form.Label className="custom-control-label" htmlFor="inlineRadio1"> Male </Form.Label>
													</div>
												</div>
												<div className="form-check form-check-inline">
													<div className="custom-control custom-radio custom-control-inline">
														<Form.Control type="radio" id="inlineRadio2" name="gender" value="Female" className="custom-control-input" />
														<Form.Label className="custom-control-label" htmlFor="inlineRadio2"> Female </Form.Label>
													</div>
												</div>
												<div className="form-check form-check-inline mt-2">
													<div className="custom-control custom-radio custom-control-inline">
														<Form.Control type="radio" id="inlineRadio3" name="gender" value="Other" className="custom-control-input" />
														<Form.Label className="custom-control-label" htmlFor="inlineRadio3"> Other </Form.Label>
													</div>
												</div>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
												<Form.Control type="text" name="email" id="Text4" placeholder="Enter Email" />
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label htmlFor="password" className="font-weight-bold text-muted text-uppercase">Password</Form.Label>
												<Form.Control type="password" name="password" id="password" placeholder="Enter Password" />
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label htmlFor="confirmPassword" name="confirmPassword" className="font-weight-bold text-muted text-uppercase">Confirm Password</Form.Label>
												<Form.Control type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" />
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label htmlFor="fileUpload" className="font-weight-bold text-muted text-uppercase">Upload File</Form.Label>
												<Form.Control type="file" id="fileUpload" name="fileUpload" />
											</div>
											<div className="col-md-12 mb-3">
												<Button variant="btn btn-primary" type="submit">
													Update Student
												</Button>
											</div>
										</Form>

									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container >

		</>
	)
}

export default Studentedit;