import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, CloseButton } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';
const QuizAttempt = () => {
	const [quiz, setQuiz] = useState(null);
	const { quizId, userId } = useParams();
	console.log(quizId, userId);
	const fetchQuiz = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/quizzes/${userId}/${quizId}/detailed-results`, {
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});
			if (response.status === 200) {
				setQuiz(response.data.data);
				console.log(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (err) {
			if (err.response) {
				handleNon200Response(err.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		}
	};
	useEffect(() => {
		fetchQuiz();
	}, [quizId, userId]);
	return (
		<>
			<Container fluid>
				<Row>

					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Quiz Attempt</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<div className="form-group mb-0">
												<Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Product" />
												<Link className="search-link" to="#">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
						<Row className="mb-4">
							<Col lg="12">
								<Card>
									<Card.Body>
										<h5 className="mb-3">Quiz Information</h5>
										<ul>
											<li>Student Name: {quiz?.quizDetails?.studentName || 'N/A'}</li>
											<li>Quiz Title: {quiz?.quizDetails?.title || 'N/A'}</li>
											<li>Score: {quiz?.quizDetails?.score || 'N/A'}</li>
											<li>Total Attempted: {quiz?.quizDetails?.totalAttempted || 'N/A'}</li>
											<li>Total Questions: {quiz?.quizDetails?.totalQuestions || 'N/A'}</li>
										</ul>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col lg="12">
								{quiz && quiz.quizDetails && quiz.quizDetails.questions.map((question, qIndex) => {
									const correctAnswer = question.answer;
									const userAnswer = quiz.detailedResults.find(result => result.questionId === question._id);
									const isCorrect = userAnswer && userAnswer.selectedOptionId && question.options.find(option => option._id === userAnswer.selectedOptionId).ans === correctAnswer;
									const backgroundColor = isCorrect ? '#b8f5b3' : '#f5bfc3';

									return (
										<Card key={qIndex} className="card-block card-stretch">
											<Card.Body className="p-0">
												<div className="align-items-center p-3" style={{ backgroundColor }}>
													<div>
														<h6 className="mb-3">Question: {qIndex + 1}. {question.questionText}</h6>
														<Form>
															{question.options.map((option, oIndex) => {
																const isSelected = userAnswer && userAnswer.selectedOptionId === option._id;
																return (
																	<div key={oIndex} className="mb-2">
																		<Form.Check
																			type="radio"
																			id={`question-${qIndex}-option-${oIndex}`}
																			label={option.text || <img src={option.image} alt="Option Image" />}
																			name={`question-${qIndex}`}
																			value={option._id}
																			checked={isSelected}
																			readOnly
																		/>
																	</div>
																);
															})}
															<div className="correct-answer mt-2">
																Correct Answer: {question.options.find(option => option.ans === correctAnswer).text}
															</div>
														</Form>
													</div>
												</div>
											</Card.Body>
										</Card>
									);
								})}
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default QuizAttempt;

