import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Pagination, Dropdown, ButtonGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from "../../../config";
import Swal from "sweetalert2";
import axios from "axios";
import { checkSession, handleNon200Response } from '../../../utils/session';
import { ThreeDots } from 'react-loader-spinner';

const QuizSubmissions = () => {
	const [quizzes, setQuizzes] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		checkSession();
	}, []);

	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [error, setError] = useState(null);
	const rowsPerPage = 10;
	const totalRows = quizzes.length;
	const totalPages = Math.ceil(totalRows / rowsPerPage);


	const fetchSubmissions = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/quizzes/${id}/results`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setQuizzes(response.data.data);
				console.log(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Unexpected Error!'
				});
			}
		} finally {
			setLoading(false);
		}
	};


	useEffect(() => {
		fetchSubmissions();
	}, [currentPage]);
	return (
		<>
			<Container fluid>
				<Row>

					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Quiz Submissions</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<div className="form-group mb-0">
												<Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Product" />
												<Link className="search-link" to="#">
													<svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
													</svg>
												</Link>
											</div>
										</Form>


									</div>


								</div>

							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Quiz Submissions</h5>
											<Button variant="btn btn-secondary btn-sm">
												<svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col">
															<label className="text-muted m-0" >User Name</label>

														</th>
														<th scope="col">
															<label className="text-muted m-0" >Email</label>

														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Quiz Name</label>

														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Total Questions</label>

														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Answers Questions</label>
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Score</label>
														</th>
														<th scope="col">
															<label className="text-muted mb-0" >Action</label>

														</th>
													</tr>
												</thead>
												<tbody>
													{loading ? (
														<tr>
															<td colSpan="6" style={{ textAlign: "center" }}>
																<ThreeDots color="#00BFFF" height={80} width={80} />
															</td>
														</tr>
													) : (quizzes.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((item) => (
														<tr key={item._id} className="white-space-no-wrap" >
															<td>{item.userName.name}</td>
															<td>{item.email}</td>
															<td>{item.quizName}</td>
															<td>{item.totalQuestions}</td>
															<td>{item.answersCount}</td>
															<td>{item.score}</td>
															<td>
																<Link to={`/quiz-attempt/${item.quizId}/${item.userId}`}>
																	<Button variant="primary" size="sm">
																		View Details
																	</Button>
																</Link>
															</td>

														</tr>
													))

													)}
												</tbody>
											</table>
											<div className="d-flex justify-content-center">
												<Pagination className="mb-0">
													<Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
													{[...Array(totalPages).keys()].map(pageNumber => (
														<Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === currentPage} onClick={() => setCurrentPage(pageNumber + 1)}>
															{pageNumber + 1}
														</Pagination.Item>
													))}
													<Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
												</Pagination>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default QuizSubmissions;
